import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import _ from 'lodash';
import { NavigateFunction } from 'react-router-dom';

function getFullUrl(path: string) {
  return `${window.location.protocol}//${window.location.host}${path}`;
}

export function getFolderLink(folderId: string, tab?: string, fullUrl = false) {
  const path = `/${folderId}/folder${tab ? `?t=${tab}` : ''}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getWorkbooksLink(tab?: string) {
  return `/workbooks${tab ? `?t=${tab}` : ''}`;
}

export function getNoWorksheetLink() {
  return '/no-worksheet';
}

export function getWorksheetLink(
  workbookId: string,
  worksheetId?: string,
  folderId?: string,
  workstepId?: string,
  fullUrl = false,
) {
  const path =
    `${folderId ? `/${folderId}` : ''}` +
    `/workbook/${workbookId}/` +
    `${worksheetId ? `worksheet/${worksheetId}` : ''}` +
    `${workstepId ? `?workstepId=${workstepId}` : ''}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getParameterWorksheetLink(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  searchParams?: URLSearchParams,
) {
  return `${getViewWorksheetLink(workbookId, worksheetId, folderId)}${
    searchParams ? `?${searchParams.toString()}` : ''
  }`;
}

export function getViewWorksheetLink(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  workstepId?: string,
  fullUrl = false,
) {
  const path = `${folderId ? `/${folderId}` : ''}/view/worksheet/${workbookId}/${worksheetId}${
    workstepId ? `?workstepId=${workstepId}` : ''
  }`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getShortViewWorksheetLink(worksheetId: string, fullUrl = false) {
  const path = `/view/${worksheetId}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getPresentWorksheetLink(workbookId: string, worksheetId: string, fullUrl = false) {
  const path = `/present/worksheet/${workbookId}/${worksheetId}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getHomeScreenAddOnLink(identifierHash: string) {
  return `/hsa?a=${identifierHash}`;
}

export function getLicenseLink() {
  return `/license`;
}

export function getUnauthorizedLink() {
  return '/unauthorized';
}

export function getLoginLink(returnTo?: string, extraSearchParams?: URLSearchParams) {
  const searchParams = new URLSearchParams(extraSearchParams);

  if (returnTo && returnTo !== 'undefined') {
    searchParams.append('returnTo', returnTo);
  }

  return _.join(_.compact(['/login', searchParams.toString()]), '?');
}

export function navigateToUnauthorizedPage() {
  goTo(getUnauthorizedLink());
}

export function navigateToForbiddenPage() {
  goTo('/forbidden');
}

export function navigateToErrorPage() {
  goTo('/load-error');
}

export function navigateToHeadlessRenderStandby() {
  goTo('/headless-capture-standby');
}

export function navigateToWorksheetInNewTab(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  viewMode = false,
) {
  const link = viewMode
    ? getViewWorksheetLink(workbookId, worksheetId, folderId)
    : getWorksheetLink(workbookId, worksheetId, folderId);
  window.open(window.location.origin + link, '_blank');
}

let path: string;
let previousPath: string;

export function setCurrentPath(pathname: string) {
  previousPath = getCurrentPath();
  path = pathname;
}

export function getPreviousPath() {
  return previousPath;
}

export function getCurrentPath() {
  return path;
}

export function getAbsoluteWorksheetURL(workbookId: string, worksheetId: string) {
  return window.location.origin + getWorksheetLink(workbookId, worksheetId);
}

export function getAbsoluteViewWorksheetURL(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  workstepId?: string,
) {
  return window.location.origin + getViewWorksheetLink(workbookId, worksheetId, folderId, workstepId);
}

export function getAbsolutePresentationWorksheetURL(workbookId: string, worksheetId: string) {
  return window.location.origin + getPresentWorksheetLink(workbookId, worksheetId);
}

export function getDisplayMode(path: string): WORKBOOK_DISPLAY {
  if (_.includes(path, 'view/')) {
    return WORKBOOK_DISPLAY.VIEW;
  } else if (_.includes(path, 'present/')) {
    return WORKBOOK_DISPLAY.PRESENT;
  } else {
    return WORKBOOK_DISPLAY.EDIT;
  }
}

export function wasWorksheet() {
  return !_.isNil(getPreviousPath()) && _.includes(getPreviousPath(), '/worksheet/');
}

export function isWorksheet() {
  return _.includes(getCurrentPath(), '/worksheet/');
}

export function isWorkbooks() {
  return _.includes(getCurrentPath(), '/workbooks') || _.includes(getCurrentPath(), '/folder');
}

let fromBuilder = false;

export function setFromBuilder(val: boolean) {
  fromBuilder = val;
}

export function getFromBuilder() {
  return fromBuilder;
}

export function goTo(location: string, replace = false) {
  if (replace) {
    return getNavigate()(location, { replace: true });
  } else {
    return getNavigate()(location);
  }
}

let navigateFn: NavigateFunction;

export function setNavigate(navigate: NavigateFunction) {
  navigateFn = navigate;
}

export function getNavigate() {
  if (!navigateFn) {
    throw new Error('The navigate function must be set before it can be used');
  }
  return navigateFn;
}

export function isWorksheetDisplayed(path: string) {
  return _.includes(path, '/worksheet/');
}

export function getWorkbookDisplayMode(path: string) {
  if (_.includes(path, '/view/')) {
    return WORKBOOK_DISPLAY.VIEW;
  } else if (_.includes(path, 'present/')) {
    return WORKBOOK_DISPLAY.PRESENT;
  } else {
    return WORKBOOK_DISPLAY.EDIT;
  }
}

export const getWorksheetPath = (
  workbookId: string,
  worksheetId: string,
  currentFolderId?: string,
  workbookDisplay?: WORKBOOK_DISPLAY,
) => {
  if (workbookDisplay === WORKBOOK_DISPLAY.EDIT) {
    return getWorksheetLink(workbookId, worksheetId, currentFolderId);
  }

  if (workbookDisplay === WORKBOOK_DISPLAY.VIEW) {
    return getViewWorksheetLink(workbookId, worksheetId, currentFolderId);
  }

  return getPresentWorksheetLink(workbookId, worksheetId);
};
