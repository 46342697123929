// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Alert, Form, FormControl, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sqWorkbenchStore } from '@/core/core.stores';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqLogsApi } from '@/sdk';
import { randomInt } from '@/utilities/utilities';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { TextButton } from '@/core/TextButton.atom';
import { DEBOUNCE } from '@/core/core.constants';

interface LogSenderModalDialogIF {
  onClose: () => void;
}

const DOWNLOAD_PATH = `${APPSERVER_API_PREFIX}${SeeqNames.API.Logs}/download`;
const COOKIE_ID = 'downloadLogsCookie';

export const LogSenderModal: React.FunctionComponent<LogSenderModalDialogIF> = ({ onClose }) => {
  const cookies = new Cookies();
  const { t } = useTranslation();
  const [busy, setBusy] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>(
    `${sqWorkbenchStore.currentUser.firstName} ${sqWorkbenchStore.currentUser.lastName}`,
  );
  const [userEmail, setUserEmail] = useState<string>(sqWorkbenchStore.currentUser.email);
  const [description, setDescription] = useState<string>('');
  const [showAlert, setShowAlert] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setValidated(!formRef.current.checkValidity());
  }, [userName, userEmail, description]);

  const closeModal = () => {
    onClose();
  };

  /**
   * Requests that appserver zip and transmit the logs to Seeq.
   */
  const sendLogs = () => {
    if (!busy) {
      setBusy(true);
      setShowAlert('');
      sqLogsApi
        .sendLogs({ name: userName, email: userEmail, description })
        .then(() => {
          successToast({ messageKey: 'LOGS.SENT' });
          closeModal();
        })
        .catch((e) => {
          setShowAlert('danger');
          errorToast({ httpResponseOrError: e, displayForbidden: true });
        })
        .finally(() => {
          setBusy(false);
        });
    }
  };

  /**
   * Downloads the zipped logs from appserver by setting the window's href to download the logs in place. It passes
   * down a tag as part of the download, checking every second for that tag to in the cookies, at which point it
   * knows the logs are downloaded and stops the loading icon and shows extra details.
   */
  const downloadLogs = () => {
    if (!busy) {
      setBusy(true);
      setShowAlert('');
      const tag = Math.abs(randomInt()).toString();
      const handlerId = setInterval(() => {
        if (cookies.get(COOKIE_ID) === tag) {
          setBusy(false);
          setShowAlert('success');
          clearInterval(handlerId);
        }
      }, DEBOUNCE.LONG);
      window.location.href = `${DOWNLOAD_PATH}?${COOKIE_ID}=${tag}`;
    }
  };

  return (
    <Modal show={true} onHide={closeModal} animation={false} data-testid="logSenderModal">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('SUPPORT_REQUEST')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form name="ctrl.form" validated={validated} ref={formRef}>
          <div className="mb10">
            <Form.Control
              data-testid="user_name"
              autoComplete="off"
              type="text"
              name="userName"
              className="width-maximum"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder={t('USER.NAME')}
              required={true}
            />
            <Form.Control.Feedback type="invalid">{t('INVALID_NAME')}</Form.Control.Feedback>
          </div>
          <div className="mb10">
            <Form.Group>
              <FormControl
                data-testid="user_email"
                autoComplete="off"
                type="email"
                name="userEmail"
                className="width-maximum"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder={t('USER.EMAIL')}
                required={true}
              />
              <Form.Control.Feedback type="invalid">{t('USER.EMAIL_INVALID')}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="mb10">
            <Form.Group>
              <FormControl
                data-testid="description"
                autoComplete="off"
                as="textarea"
                name="description"
                className="width-maximum"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('LOGS.DESCRIPTION')}
                required={true}
              />
            </Form.Group>
          </div>
          <Alert
            className={`sq-alert-${showAlert}`}
            variant={showAlert}
            show={showAlert.length > 0}
            dismissible={false}>
            {showAlert === 'success' && (
              <div>
                <span>{t('LOGS.DOWNLOAD_SUCCESS_PART_1')} </span>
                <Alert.Link
                  href={`https://www.seeq.com/support?form[Email]=${userEmail}&form[Name]=${userName}&form[Message]=${description}`}>
                  {' '}
                  {t('LOGS.DOWNLOAD_SUCCESS_PART_2')}
                </Alert.Link>
              </div>
            )}
            {showAlert === 'danger' && <span>t('LOGS.SEND_FAILED')</span>}
          </Alert>
          <div className="flexColumnContainer flexJustifyCenter mt10">
            <TextButton
              variant="theme"
              extraClassNames="mr15"
              testId="download_logs_button"
              disabled={busy}
              label="LOGS.DOWNLOAD"
              icon={busy ? 'fa-spinner fa-spin fa-pulse fa-lg' : 'fa-download fa-lg'}
              iconStyle="white"
              onClick={downloadLogs}
            />
            <TextButton
              variant="theme"
              testId="send_logs_button"
              disabled={busy || validated}
              label="LOGS.SEND"
              onClick={sendLogs}
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
